body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  background-color: #0070ff;
  text-align: center;
  padding: 5px;
  color: #fff;
  margin-top: auto;
  bottom:0;
  font-size: 13px;
}

.link {
  margin: 5px;
  padding: 8px 15px;
  width: 40px;
  color:#fff;
  text-decoration: none;
  font-weight: 800;
}
.link:hover {
  color:black;
  background-color: #fff;
  border-radius: 15px;
}


.accedi, .dashboard, .homepage, .addThings {
  padding: 0 30px;
  min-height: 50%;
}

nav {
  background-color: #0070ff;
  padding: 20px 25px;
}





input.inputAggiungi, input.cerca {
  padding: 5px;
  margin: 10px 0;
  width: 250px;
}

select.inputAggiungi {
  padding: 0px 1em 0 0;
  margin: 10px 0;
  width: 250px;
  font-family: inherit;
  font-size: inherit;
  height: 2rem;
}

span.inputAggiungi, input[type="checkbox"] {
  padding: 5px;
  margin: 10px 0;
}


.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-dx, .box-sx {
  margin: 20px 10px;
  padding: 20px 50px;
  flex-grow: 100;
  flex-shrink: 1;
  flex-basis: 3px;
  align-self: auto;
  border-radius: 15px;
  box-shadow: 0 0 15px 4px #c7dfff;
  border: 1px solid #0070ff;
}
.box-dx {
  order: 1;
}

.box-sx {
  order: 2;
}

input, select {
  border-radius: 10px;
  font-size: 18px;
  padding: 5px;
}
input[type="checkbox"]{
  width: 25px;
  height: 25px;
}

input[type="text"],
input[type="password"]{
  border: 1px solid grey;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  margin: 1rem 0;
}

.divRegistrazione,
.containerLogin,
.containerUtente {
  border-radius: 15px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgb(236, 236, 236);
  margin: auto;
  padding: 2rem 0;
  width: 50%;
}

.formRegistrazione,
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*PER IPHONE*/
@media only screen and (max-width: 768px) {
  .divRegistrazione,
  .containerLogin,
  .containerUtente {
    border-radius: 15px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    border: 1px solid rgb(236, 236, 236);
    margin: auto;
    padding: 1rem;
    width: -moz-available;
  }
  .divRegistrazione {
    width: 90%;
  }

  .link {
    margin: 0;
    padding: 8px 15px;
    width: 30px;
    color: #fff;
    text-decoration: none;
    font-weight: 800;
  }
}

.accedi {
    margin: 1rem 5rem;
    padding: 3rem 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.containerLogin,
.containerUtente{
    margin: 2rem 0rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerAzioni {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.error {
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    margin: 1rem;
    text-align: center;
}

.formLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 1rem;
}

.faiLogin {
    margin: 1rem 0 !important;
}

@media only screen and (max-width: 768px) {
    .containerLogin,
    .containerUtente {
        width: 100%;
        padding: 1rem 4rem;
    }

}
.boxCerca {
    margin: 15px 0;
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    border: 1px solid rgb(236, 236, 236);
}

.boxList {
    height: 100%;
}

.boxList,
.risultatiTrovati {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

h2 {
    padding: 0 15px;
}

li {
    padding: 0.8rem;
}

@media only screen and (max-width: 768px) {
    h2 {
        padding: 0;
    }
    
    li {
        padding: 0.8rem 0.3rem;
        font-size: 15px;
    }
    
}
.preso {
    list-style-type: disc;
    font-weight: 700;
}

.nonpreso {
    list-style-type: circle;
}

button {
    cursor: grab;
}

button.cancel,
button.aggiungi {
    padding: 8px;
    margin: 4px;
    font-size: 14px;
}

button.usato,
button.nonusato,
button.preso,
button.nonpreso {
    color: #000;
}

button.usato,
button.preso {
    background-color: rgb(176, 248, 224);
    border-radius: 10px;
    border: none;
    font-weight: 700;
    width: 5rem;
}

button.nonusato,
button.nonpreso {
    background-color: rgb(249, 200, 200);
    border-radius: 10px;
    border: none;
    width: 5rem;
}

button.cancella:hover,
button.cancel:hover,
button.btnLogout:hover {
    background-color: rgb(255, 223, 223);
    box-shadow: 1px 2px;
}

button.preso:hover,
button.nonpreso:hover,
button.usato:hover,
button.nonusato:hover {
    border: 1px solid black;
    box-shadow: 1px 2px;
}

button.preso,
button.nonpreso,
button.usato,
button.nonusato,
button.cancella,
button.aggiorna,
button.cerca,
button.btnFiltro,
input.faiLogin {
    /*width: 5.35em;*/
    padding: 8px;
    margin: 4px;
    font-size: 14px;
}


button.aggiungi,
button.aggiorna,
button.cerca,
button.btnGoto{
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgb(16, 181, 16);
    color: rgb(16, 181, 16);
}

button.aggiungi:hover,
button.aggiorna:hover,
button.cerca:hover,
button.btnGoto:hover {
    background-color: rgb(212, 255, 212);
    box-shadow: 1px 2px;
}


button.cancella,
button.cancel,
button.btnLogout {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid red;
    color: red;
    width: 5rem;
}

.btnFiltro,
.faiLogin {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #000;
    color: #000;
}

.btnFiltro:hover,
.faiLogin:hover {
    background-color: rgb(198, 198, 198);
    box-shadow: 1px 2px;
}

.btnGoto {
    width: 85%;
    padding: 0.2rem 1rem;
    margin: 1rem 1.5rem;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
}
.btnLogout {
    padding: 0.2rem 1rem;
    margin: 1rem 1.5rem;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}


