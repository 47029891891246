.preso {
    list-style-type: disc;
    font-weight: 700;
}

.nonpreso {
    list-style-type: circle;
}

button {
    cursor: grab;
}

button.cancel,
button.aggiungi {
    padding: 8px;
    margin: 4px;
    font-size: 14px;
}

button.usato,
button.nonusato,
button.preso,
button.nonpreso {
    color: #000;
}

button.usato,
button.preso {
    background-color: rgb(176, 248, 224);
    border-radius: 10px;
    border: none;
    font-weight: 700;
    width: 5rem;
}

button.nonusato,
button.nonpreso {
    background-color: rgb(249, 200, 200);
    border-radius: 10px;
    border: none;
    width: 5rem;
}

button.cancella:hover,
button.cancel:hover,
button.btnLogout:hover {
    background-color: rgb(255, 223, 223);
    box-shadow: 1px 2px;
}

button.preso:hover,
button.nonpreso:hover,
button.usato:hover,
button.nonusato:hover {
    border: 1px solid black;
    box-shadow: 1px 2px;
}

button.preso,
button.nonpreso,
button.usato,
button.nonusato,
button.cancella,
button.aggiorna,
button.cerca,
button.btnFiltro,
input.faiLogin {
    /*width: 5.35em;*/
    padding: 8px;
    margin: 4px;
    font-size: 14px;
}


button.aggiungi,
button.aggiorna,
button.cerca,
button.btnGoto{
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgb(16, 181, 16);
    color: rgb(16, 181, 16);
}

button.aggiungi:hover,
button.aggiorna:hover,
button.cerca:hover,
button.btnGoto:hover {
    background-color: rgb(212, 255, 212);
    box-shadow: 1px 2px;
}


button.cancella,
button.cancel,
button.btnLogout {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid red;
    color: red;
    width: 5rem;
}

.btnFiltro,
.faiLogin {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #000;
    color: #000;
}

.btnFiltro:hover,
.faiLogin:hover {
    background-color: rgb(198, 198, 198);
    box-shadow: 1px 2px;
}

.btnGoto {
    width: 85%;
    padding: 0.2rem 1rem;
    margin: 1rem 1.5rem;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
}
.btnLogout {
    padding: 0.2rem 1rem;
    margin: 1rem 1.5rem;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

