html, body {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  background-color: #0070ff;
  text-align: center;
  padding: 5px;
  color: #fff;
  margin-top: auto;
  bottom:0;
  font-size: 13px;
}

.link {
  margin: 5px;
  padding: 8px 15px;
  width: 40px;
  color:#fff;
  text-decoration: none;
  font-weight: 800;
}
.link:hover {
  color:black;
  background-color: #fff;
  border-radius: 15px;
}


.accedi, .dashboard, .homepage, .addThings {
  padding: 0 30px;
  min-height: 50%;
}

nav {
  background-color: #0070ff;
  padding: 20px 25px;
}





input.inputAggiungi, input.cerca {
  padding: 5px;
  margin: 10px 0;
  width: 250px;
}

select.inputAggiungi {
  padding: 0px 1em 0 0;
  margin: 10px 0;
  width: 250px;
  font-family: inherit;
  font-size: inherit;
  height: 2rem;
}

span.inputAggiungi, input[type="checkbox"] {
  padding: 5px;
  margin: 10px 0;
}


.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-dx, .box-sx {
  margin: 20px 10px;
  padding: 20px 50px;
  flex-grow: 100;
  flex-shrink: 1;
  flex-basis: 3px;
  align-self: auto;
  border-radius: 15px;
  box-shadow: 0 0 15px 4px #c7dfff;
  border: 1px solid #0070ff;
}
.box-dx {
  order: 1;
}

.box-sx {
  order: 2;
}

input, select {
  border-radius: 10px;
  font-size: 18px;
  padding: 5px;
}
input[type="checkbox"]{
  width: 25px;
  height: 25px;
}

input[type="text"],
input[type="password"]{
  border: 1px solid grey;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  margin: 1rem 0;
}

.divRegistrazione,
.containerLogin,
.containerUtente {
  border-radius: 15px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgb(236, 236, 236);
  margin: auto;
  padding: 2rem 0;
  width: 50%;
}

.formRegistrazione,
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*PER IPHONE*/
@media only screen and (max-width: 768px) {
  .divRegistrazione,
  .containerLogin,
  .containerUtente {
    border-radius: 15px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    border: 1px solid rgb(236, 236, 236);
    margin: auto;
    padding: 1rem;
    width: -moz-available;
  }
  .divRegistrazione {
    width: 90%;
  }

  .link {
    margin: 0;
    padding: 8px 15px;
    width: 30px;
    color: #fff;
    text-decoration: none;
    font-weight: 800;
  }
}
