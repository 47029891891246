.accedi {
    margin: 1rem 5rem;
    padding: 3rem 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.containerLogin,
.containerUtente{
    margin: 2rem 0rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerAzioni {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.error {
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    margin: 1rem;
    text-align: center;
}

.formLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 1rem;
}

.faiLogin {
    margin: 1rem 0 !important;
}

@media only screen and (max-width: 768px) {
    .containerLogin,
    .containerUtente {
        width: 100%;
        padding: 1rem 4rem;
    }

}