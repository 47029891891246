.boxCerca {
    margin: 15px 0;
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    border: 1px solid rgb(236, 236, 236);
}

.boxList {
    height: 100%;
}

.boxList,
.risultatiTrovati {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

h2 {
    padding: 0 15px;
}

li {
    padding: 0.8rem;
}

@media only screen and (max-width: 768px) {
    h2 {
        padding: 0;
    }
    
    li {
        padding: 0.8rem 0.3rem;
        font-size: 15px;
    }
    
}